import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PageLinks from './PageLinks';
import { useUserContext } from '../UserContext';
import ErrorPopup from './ErrorPopup'; // Adjust the import path

const UserMenu = ({ userUsername, onSignOut, insightCredits }) => (
    <div className="flex items-center">
        {/* Display Insight Credits - Insight Coins, text length depends on the screen size */}
        <span className="hidden sm:block mr-4">
            Credits: {insightCredits !== null ? insightCredits : "..."}
        </span>
        <span className="block sm:hidden mr-4">
            Credits: {insightCredits !== null ? insightCredits : "..."}
        </span>

        {/* User Username */}
        {userUsername ?
            <div className="flex items-center px-4 py-2 border-gray-800 rounded-md">
                <i className="fas fa-user-circle fa-lg mr-2"></i>
                {userUsername}
            </div>
            : null}

        {/* Sign Out Button */}
        <button onClick={onSignOut} className="bg-blue-600 text-white mx-4 px-4 py-2 rounded-md hover:bg-blue-700 transition-colors duration-300">
            Sign Out
        </button>
    </div>
);


const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { currentUser, logOut, username, hasApprovedAccess, emailVerified, insightCredits, errors,clearAllErrors} = useUserContext();
    const navigate = useNavigate();

    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
    const closeMenu = () => setIsMenuOpen(false);
    const handleSignOut = async () => {
        try {
            await logOut();
            navigate("/");
        } catch (error) {
            console.log('Sign-out error:', error.message);
        }
    };

    const handleCloseErrorPopup = () => {
        // You might want to clear all errors at once, or clear specific ones
        // removeError(error.id) // If you need to clear individual errors by ID
        clearAllErrors();  // Clears all errors from state
    };


    return (
        <div>
            {
                !currentUser ?
                    null
                    :
                    <nav className="fixed w-full z-10 m-0 bg-white">
                        {/* Backdrop for closing the menu */}
                        <div className={`fixed inset-0 bg-black bg-opacity-50 lg:hidden ${isMenuOpen ? 'block' : 'hidden'}`} onClick={closeMenu}> </div>
                        <div className="mx-auto px-4 flex items-center justify-between h-16">
                            <Link to="/">
                                <img src="/assets/icons/logo_feg.svg" className="nav-logo h-8 md:h-10 ml-5" alt="logo" />
                            </Link>
                            {(!hasApprovedAccess || !emailVerified) ?
                                null :

                                // Render PageLinks only all above conditions are met:
                                <div className="flex items-center justify-center flex-grow">
                                    <div className="hidden lg:flex items-center space-x-4">
                                        <PageLinks parentClass="flex items-center" closeMenu={closeMenu} />
                                    </div>
                                </div>
                            }
                            <div className="flex items-center ml-auto">
                                {/* Error Popup */}
                                {errors.length > 0 && (
                                    <ErrorPopup errors={errors} onClose={(handleCloseErrorPopup)} />
                                )}
                                <UserMenu userUsername={username} onSignOut={handleSignOut} insightCredits={insightCredits} />
                                <button type="button" className="p-2 text-xl lg:hidden" onClick={toggleMenu} aria-label="Toggle Menu">
                                    <i className="fas fa-bars text-primary-dark"></i>
                                </button>
                            </div>
                        </div>


                        {(!hasApprovedAccess || !emailVerified) &&
                            <PageLinks
                                parentClass={`${isMenuOpen ? 'fixed inset-0 bg-white' : 'hidden'
                                    } lg:hidden flex flex-col items-center justify-start h-1/2 w-64 py-5`}
                                closeMenu={closeMenu}
                            />
                        }
                    </nav>
            }
        </div >
    );
};

export default Navbar;
