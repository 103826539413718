import React from 'react';
import { Navigate } from 'react-router-dom';
import Loader from '../components/Loader';
import { sendEmailVerification } from 'firebase/auth';
import { useUserContext } from '../UserContext';

export default function ApprovalPage() {
  const { currentUser, hasApprovedAccess, authCheckComplete, emailVerified } = useUserContext();
  console.log('ApprovalPage Beginning: currentUser, authCheckComplete, hasApprovedAccess, emailVerified: ', currentUser, authCheckComplete, hasApprovedAccess, emailVerified);
  if (!authCheckComplete) return <Loader />; // Replace with your actual loading component or indicator

  if (!currentUser) return <Navigate to="/signin" replace />;

  if (hasApprovedAccess && emailVerified)
    return <Navigate to="/gym" replace />;

  console.log('ApprovalPage Going to Render Children');
  const resendVerificationEmail = async () => {
    if (currentUser) {
      await sendEmailVerification(currentUser);
      // Inform the user that the verification email was resent
    }
  };
  return (
    <div className="h-screen flex flex-col md:flex-row items-center justify-center ">
      <div className="md:w-1/2  flex flex-col items-center justify-center">
        <h1 className="text-xl sm:text-xl md:text-2xl lg:text-3xl text-black font-bold flex items-center justify-center mb-5">
          Welcome ! &nbsp; <span className='text-red-500'>  BUT:</span>
        </h1>
        <div className="md:w-2/3 flex flex-col items-center justify-center">
          {emailVerified ?
            <div>
              <p> You have sucessfully signed in. But our system has detected that you are not using an approved company or partner email domain.  </p>
              <p> Pulse Platform is still in Beta and not available to general public. If you would like to request exclusive access please send us an email to <span className='text-sky-500'> support@ftredge.com </span> </p>
            </div>
            :
            <div>
              <p>Your email is not verified. Please check your inbox.</p>
              <button onClick={resendVerificationEmail}>Resend Verification Email</button>
            </div>
          }
        </div>
      </div>

      <div className="md:w-1/2 mr-15 mt-25 mb-15">
        <img
          src="/assets/images/time-travel.png"
          alt="Time Travel"
          className="h-2/3 object-cover "
        />
      </div>

    </div>
  )
}
