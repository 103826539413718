// import { getAuth } from 'firebase/auth';
// import { useUserContext } from '../UserContext';
// Usage in API functions
import { handleApiResponse, enhanceUnhandledApiCallError } from './errorHandlingInAPI';

let API_BASE_URL;

if (process.env.REACT_APP_ENV === 'production') {
    API_BASE_URL = 'https://ftredge.com';
} else if (process.env.REACT_APP_ENV === 'staging') {
    // API_BASE_URL = 'https://staging-api.ftredge.com'; // Google Global Load Balancer integrated with Cloud Run --> currently disabled to save cost
    API_BASE_URL = 'https://ipulse-core-app-staging-37jxapyarq-uc.a.run.app'; // Google Cloud Run default URL
    // API_BASE_URL= 'https://apigtwstaging-5o9ih5u0.uc.gateway.dev' // Google Cloud API Gateway -> not working causing CORS issue before hitting Cloud Run
} else {
    API_BASE_URL = 'http://localhost:8000';
}


const USER_PROFILES_API_PATH= `${API_BASE_URL}/api/v1/pulseroot/core/user/user-profiles/`;
const USER_STATUS_API_PATH= `${API_BASE_URL}/api/v1/pulseroot/core/user/user-statuses/`;
const PROFILE_DOC_PREFIX="userusrprfl_";
const STATUS_DOC_PREFIX="userusrsttus_";

console.log('API_BASE_URL:', API_BASE_URL);
console.log("process.env:", process.env);

// ##################   USER-PROFILES ####################


async function getUserProfile(user_uid,userFirebaseAuthToken) {
    console.log('getUserProfile', user_uid);
    if (!user_uid) {
        throw new Error("user_uid is required and cannot be null or empty.");
    }
    try {
        // const auth = getAuth();
        // const token = await auth.currentUser.getIdToken();
        const doc_uid = PROFILE_DOC_PREFIX  + user_uid;
        const response = await fetch(`${USER_PROFILES_API_PATH}${doc_uid}`, {
            headers: { 'Authorization': `Bearer ${userFirebaseAuthToken}` }
        });

        if (!response.ok) {
            // Call handleApiResponse which throws an error
            await handleApiResponse(response);
            // The above line will throw, so the next line will not be executed and nothing is actually returned
       }
        return await response.json();
    } catch (error) {
        enhanceUnhandledApiCallError(error);  // Handle and rethrow the error with enhanced messaging
    }
}

async function updateUserProfile(user_uid, fieldsToUpdate, userFirebaseAuthToken) {
    try {
        // const auth = getAuth();
        // const token = await auth.currentUser.getIdToken();
        const doc_uid = PROFILE_DOC_PREFIX  + user_uid;
        const response = await fetch(`${USER_PROFILES_API_PATH}${doc_uid}`, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${userFirebaseAuthToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(fieldsToUpdate)
        });

        if (!response.ok) {
            // Call handleApiResponse which throws an error
            await handleApiResponse(response);
            // The above line will throw, so the next line will not be executed and nothing is actually returned
       }

        return await response.json();
    } catch (error) {
        enhanceUnhandledApiCallError(error);  // Handle and rethrow the error with enhanced messaging
    }
}


// #######################  USER-STATUSES ###########################

async function getUserStatus(user_uid, userFirebaseAuthToken) {
    try {;
        // const auth = getAuth();
        // const token = await auth.currentUser.getIdToken(true); // The "true" in getIdToken(true) forces refresh of the token, to ensure custom calims are updated.
        const doc_uid = STATUS_DOC_PREFIX  + user_uid;
        const response = await fetch(`${USER_STATUS_API_PATH}${doc_uid}`, {
            headers: { 'Authorization': `Bearer ${userFirebaseAuthToken}` }
        });

        if (!response.ok) {
             // Call handleApiResponse which throws an error
             await handleApiResponse(response);
             // The above line will throw, so the next line will not be executed and nothing is actually returned
        }

        return await response.json();
    } catch (error) {
        enhanceUnhandledApiCallError(error);  // Handle and rethrow the error with enhanced messaging
    }
}

export { getUserProfile, updateUserProfile, getUserStatus };
