import React, { useState, useEffect } from 'react';
import { useUserContext } from '../UserContext'; // Adjust the import path as necessary
import { getUserStatus } from '../services/userAPIServices'; // Implement this API call
import FieldReadOnly from './FieldReadOnly';
import Loader from './Loader';
import { formatDateStringForDisplay} from '../utils/utils';
const UserAccountStatus = () => {
    const [userAccountStatusData, setUserAccountStatusData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const { currentUser, token } = useUserContext();


    useEffect(() => {
        const fetchUserStatusData = async () => {
            if (currentUser) {
                try {
                    setIsLoading(true);
                    const data = await getUserStatus(currentUser.uid, token);
                    console.log('User Status data', data)
                    setUserAccountStatusData(data);
                } catch (err) {
                    setError('Error fetching user status data: ' + err);
                } finally {
                    setIsLoading(false);
                }
            }
        };
        fetchUserStatusData();
    }, [currentUser,token]);

    if (isLoading) return <Loader />;
    if (!userAccountStatusData) {
        return (
            <div className="flex justify-center mt-25 p-5">
                <div className="w-full max-w-2xl p-6 bg-white rounded-lg border shadow-md">
                    <h1 className="text-2xl font-bold text-center mb-6">My Account Status</h1>
                    <div className="text-red-500 mb-4">User Account Status data not found for User UID: {currentUser.uid} </div>
                </div>
            </div>
        );
    };

    return (
        <div className="flex justify-center mt-25 p-5">
            <div className="w-full max-w-2xl p-6 bg-white rounded-lg border shadow-md">
                <h1 className="text-2xl font-bold text-center mb-6">My Account</h1>
                {error && <div className="text-red-500 mb-4">{error}</div>}
                <FieldReadOnly fieldName="Access Groups" value={JSON.stringify(userAccountStatusData.iam_groups)} />
                <FieldReadOnly fieldName="Subscription Plan" value={userAccountStatusData.sbscrptn_plan} />
                <FieldReadOnly fieldName="Subscription Status" value={userAccountStatusData.sbscrptn_status} />
                <FieldReadOnly fieldName="Subscription Start Date" value={formatDateStringForDisplay(userAccountStatusData.sbscrptn_start_date)} />
                <FieldReadOnly fieldName="Subscription End Date" value={formatDateStringForDisplay(userAccountStatusData.sbscrptn_end_date)} />
                <FieldReadOnly fieldName="Subscription Insight Credits" value={userAccountStatusData.sbscrptn_insight_credits.toString()} />
                <FieldReadOnly fieldName="Insight Credits Updated Since" value={formatDateStringForDisplay(userAccountStatusData.sbscrptn_ins_crdts_updtd_since_datetime)} />
                <FieldReadOnly fieldName="Extra Insight Credits" value={userAccountStatusData.extra_insight_credits.toString()} />
                <FieldReadOnly fieldName="Payment References" value={Array.isArray(userAccountStatusData.payment_refs_uids) ? userAccountStatusData.payment_refs_uids.join(', ') : ''} />
            </div>
        </div>
    );
};

export default UserAccountStatus;