import React from 'react'
import { Navigate } from 'react-router-dom'
import { useUserContext } from '../../UserContext';
import Loader from '../Loader';


const RoutesForApprovedVerifiedUsers = ({ children }) => {
  // const { currentUser, authCheckComplete, hasApprovedAccess, isUserDataLoaded, isUserDataLoading } = useUserContext();
  // if (!authCheckComplete  || isUserDataLoading ) return <Loader />
  // if (!currentUser) return <Navigate to="/signin" />;
  // if (!isUserDataLoaded  || !hasApprovedAccess || !currentUser.emailVerified) return <Navigate to="/approval" />;

  const { currentUser, authCheckComplete, hasApprovedAccess , emailVerified } = useUserContext();
  console.log('RoutesForApprovedUsers Beginning: currentUser, authCheckComplete, hasApprovedAccess, emailVerified: ', currentUser, authCheckComplete, hasApprovedAccess, emailVerified);
  if (!authCheckComplete ) return <Loader />
  if (!currentUser) return <Navigate to="/signin" />;
  if (!hasApprovedAccess || !emailVerified) return <Navigate to="/approval" />;

  console.log('RoutesForApprovedUsers Going to Render Children');
  
  return children;

};


export default RoutesForApprovedVerifiedUsers;