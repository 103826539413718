import React from 'react';

const ErrorPopup = ({ errors, onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-55">
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-red-100 p-6 rounded-lg shadow-md border border-red-400 z-55">
        <div className="flex items-center mb-4">
          
          <h2 className="text-lg font-semibold text-red-800">Oops! Something went wrong.</h2>
        </div>
        <ul className="list-disc ml-6">
          {errors.map(error => (
            <li key={error.id} className="mb-2 text-red-700">
              {error.message}
            </li>
          ))}
        </ul>
        <button 
          onClick={onClose} 
          className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 mt-4"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default ErrorPopup;
