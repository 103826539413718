
import { useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom'; // Import Navigate
import Navbar from "./components/Navbar";
import Announcement from "./components/Announcement";
import Footer from "./components/Footer";
import { UserContextProvider } from './UserContext';
import AppRoutes from './AppRoutes';


export default function App() {
  const navigate = useNavigate();
  const location = useLocation();


  //Set lastLocation in sessionStorage whenever the location changes
  useEffect(() => {
    sessionStorage.setItem('lastLocation', location.pathname);
    console.log("Saving location", location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    const lastLocation = sessionStorage.getItem('lastLocation');
    window.scrollTo(0, 0);
    if (lastLocation && lastLocation !== location.pathname) {
      console.log("Navigating to location", location);
      navigate(lastLocation);
    }
  }, [navigate, location]);

  // function RestoreRoute() {
  //   const navigate = useNavigate();
  //   const location = useLocation();
  
  //   // Effect for restoring the route only on the initial render
  //   useEffect(() => {
  //     const lastLocation = sessionStorage.getItem('lastLocation');
  
  //     // Navigate only if there's a lastLocation and we're not already there
  //     if (lastLocation && location.pathname !== lastLocation) {
  //       navigate(lastLocation);
  //     }
  //   }, [navigate,location]); // Removed location from dependencies to avoid re-triggering
  
  //   // Effect for storing the current location whenever it changes
  //   useEffect(() => {
  //     sessionStorage.setItem('lastLocation', location.pathname);
  //   }, [location.pathname]); // Depend on pathname only to avoid unnecessary triggers
  
  //   return null;
  // }





  console.log('Inside App.js  code');
  return (
    <UserContextProvider>
      <>
      {/* <RestoreRoute /> */}
        <div className="flex flex-col min-h-screen">
          <div className="fixed top-0 w-full z-50 bg-transparent">
            <Announcement />
            <Navbar />
          </div>
          <div className="flex-grow" style={{ paddingTop: '100px' }}>
          <AppRoutes />
        </div>
          <Footer />
        </div>
      </>
    </UserContextProvider>

  );
};
