import React, { useState,useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useUserContext } from '../UserContext';
// import { useNavigate } from 'react-router-dom';
const SignIn = () => {
  // const REDIRECT_AFTER_SIGNIN_PAGE = '/gym'; // Redirect to /gym page upon successful sign-in
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { signIn, googleSignIn, errors, clearAllErrors, clearErrorsByType} = useUserContext();
  // const navigate = useNavigate(); // Initialize the useNavigate hook
  // const navigate = useNavigate(); // Hook from React Router for navigation


  useEffect(() => {
    return () => {
      // This will run right before the SignIn component unmounts
      clearErrorsByType('authentication'); 
    };
  }, [clearErrorsByType]);

  const onSignin = async (e) => {
    e.preventDefault();
    try {
      clearAllErrors(); // Reset previous error state before attempting to sign in
      const userCredential = await signIn(email, password);
      console.log('User signed in:', userCredential.user); // Debugging
      // navigate(REDIRECT_AFTER_SIGNIN_PAGE); // Redirect to /gym page upon successful sign-in
    } catch (error) {
      // Error is already handled in signUp UserContext function, additional handling can be done here if needed
       console.error('Error during sign in logging within signin:', error); // Additional error handling
  }
};

  const onGoogleSignIn = async () => {
    clearAllErrors(); 
    try {
      const userCredential = await googleSignIn();
      console.log('User signed in:', userCredential.user); // Debugging
      // navigate(REDIRECT_AFTER_SIGNIN_PAGE); // Redirect to /gym page upon successful sign-in
    } catch (error) {
      console.error('Error during sign in, logging within GoogleSignin:', error); // Additional error handling
      // Error is already handled in signUp UserContext function, additional handling can be done here if needed
    }
  };
  

  return (
    <div className="flex flex-col mt-30 md:mt-0  md:flex-row h-screen">
      <div className="hidden md:flex md:flex-1  items-center  md:justify-center "> {/* Image container */}
        <img
          src='/assets/icons/logomark_feg.svg'
          alt="Logo"
          className=" md:h-1/3" />
      </div>


      <div className="   md:flex-1 flex flex-row items-center  justify-center p-12"> {/* Form container */}
        <div className=" w-130 space-y-8 items-center justify-center"> {/* Form width */}
          <h2 className="text-3xl  mb-6 color-two-dark  text-left">
            Back to Wealth Management.
          </h2>
          <div className=" w-100 space-y-8"> {/* Form width */}
            <h1 className="text-3xl  mb-6 color-two-dark  text-center">
              Sign in to pulse.
            </h1>

            {errors.length > 0 && (
                <div className="mb-4 text-red-500 text-center">
                  {errors.map(error => (
                    <div key={error.id}>{error.message}</div>
                  ))}
                </div>
              )}
            <form className="space-y-6" onSubmit={onSignin}>
              <div>
                <label
                  htmlFor="email-address"
                  className="block px-4 text-gray-700 text-sm font-medium mb-2"
                >
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  required
                  placeholder="Email address"
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-4 py-2 border rounded-2xl focus:outline-none focus:ring focus:border-blue-500"
                />
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block px-4 text-gray-700 text-sm font-medium mb-2"
                >
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  required
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full px-4 py-2 border rounded-2xl focus:outline-none focus:ring focus:border-blue-500"
                />
              </div>
              <div className="flex justify-center">
                <button
                  type="submit"
                  onSubmit={onSignin}
                  className="w-full bg-blue-500 text-white rounded-2xl py-2 hover:bg-blue-600"
                >
                  Sign in
                </button>
              </div>
              <div className="flex justify-center">
                <button
                  type="button"
                  onClick={onGoogleSignIn}
                  className="w-full flex justify-center items-center  border text-black rounded-2xl py-2 hover:bg-gray-300"
                >
                  <img
                    src="/assets/icons/google.svg" // Path to SVG in the public directory
                    alt="Google Icon"
                    style={{ height: '1.5em', width: '1.5em', marginRight: '4px' }}
                  />
                  Sign in with Google
                </button>
              </div>
              <p className="text-gray-700 text-sm mt-4 text-center">
                No account yet?{' '}
                <NavLink to="/signup" className="text-blue-500">
                  Sign up
                </NavLink>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>

  );
};

export default SignIn;