export function handleApiResponse(response) {
    return response.json().then(data => {
        const status = response.status;
        let errorMessage = `HTTP status ${status}`;
        switch (status) {
            case 400: errorMessage += " - Bad Request. Check the request parameters."; break;
            case 401:
            case 403: errorMessage += " - Unauthorized or Forbidden. Check authentication credentials."; break;
            case 404: errorMessage += " - Not Found. The requested resource doesn't exist."; break;
            case 500: errorMessage += " - Internal Server Error. Something went wrong on the server."; break;
            case 503: errorMessage += " - Service Unavailable. The server is temporarily unavailable."; break;
            default:  errorMessage += " - Unhandled HTTP status. Please review the request."; break;
        }
        if (data && data.message) {
            errorMessage += ` Server message: ${data.message}`;
        }
        throw new Error(errorMessage);
    });
}

/**
 * Enhance and rethrow the error with a user-friendly message.
 * @param {Error} error - The original error object caught.
 */
export function enhanceUnhandledApiCallError(error) {
    if (error instanceof TypeError && error.message === "Failed to fetch") {
        error.message = 'Failed to fetch. Unable to reach the server. Please check your internet connection.';
    } else if (!error.message || error.message === "An unexpected error occurred.") {
        // Default error message for unhandled situations
        error.message = 'An unexpected error occurred. Please try again.';
    }

    // Log the error for debugging purposes
    console.error('Enhanced Error Message:', error.message, error.stack);
    
    // Rethrow the error to be caught by the calling code, which can handle showing it to the user
    throw error;
}