import React, { createContext, useState, useEffect, useContext,useCallback } from 'react';
import { auth } from './firebaseConfig';
import Loader from "./components/Loader";
import { getUserStatus } from './services/userAPIServices'; // Make sure this is correctly imported
import { v4 as uuidv4 } from 'uuid'; // For generating unique IDs

import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    onAuthStateChanged,
    signOut,
    GoogleAuthProvider,
    signInWithPopup
} from "firebase/auth";

const userContext = createContext();

export const useUserContext = () => useContext(userContext);

export const UserContextProvider = ({ children }) => {

    const [errors, setErrors] = useState([]); // Array to store error objects
    const [currentUser, setCurrentUser] = useState(null);
    const [authCheckComplete, setAuthCheckComplete] = useState(false);
    const [token, setToken] = useState('');
    const [userAuthInfo, setUserAuthInfo] = useState({
        email: null,
        username: null,
        emailVerified: null,
        hasApprovedAccess: null,
        userTypes: {},
        insightCredits: 0
    });

    // Helper function to add errors, memoized with useCallback
    const addError = useCallback((message, type) => {
        setErrors(prevErrors => [...prevErrors, { id: uuidv4(), message, type }]);
    }, []);

    // Helper function to remove errors by ID, memoized with useCallback
    const removeError = useCallback((errorId) => {
        setErrors(prevErrors => prevErrors.filter(error => error.id !== errorId));
    }, []);

    // Helper function to clear all errors, memoized with useCallback
    const clearAllErrors = useCallback(() => {
        setErrors([]);
    }, []);

    const clearErrorsByType = useCallback((type) => {
        setErrors(prevErrors => prevErrors.filter(error => error.type !== type));
      }, []);  // Dependencies can be added if they affect how errors are cleared
    

    // SignIn function with useCallback
    const signIn = useCallback(async (email, password) => {
        try {
            setErrors([]); // Clear errors before attempting sign-in
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            return userCredential; // Ensure this is returned
        } catch (error) {
            addError(`${error.message}`, "authentication"); // Add error to state
            throw error; // Rethrow the error if you want calling code to handle it as well
        }
    }, [addError]); // Add any dependencies if there are any


    // SignUp function with useCallback
    const signUp = useCallback(async (email, password) => {
        try {
            setErrors([]); // Clear errors before attempting sign-up
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            return userCredential;
        } catch (error) {
            addError(`${error.message}`, "authentication");
            throw error;
        }
    }, [addError]);

    // Google SignIn function with useCallback
    const googleSignIn = useCallback(async () => {
        try {
            setErrors([]); // Clear errors before attempting sign-in with Google
            const userCredential = await signInWithPopup(auth, new GoogleAuthProvider());
            return userCredential; // Ensure this is returned
        } catch (error) {
            addError(`${error.message}`, "authentication");
            throw error;
        }
    }, [addError]);

    // LogOut function with useCallback
    const logOut = useCallback(async () => {
        try {
            await signOut(auth);
            setCurrentUser(null);
            setUserAuthInfo({
                email: null,
                username: null,
                emailVerified: null,
                hasApprovedAccess: null,
                userTypes: {},
            });
        } catch (error) {
            addError(`Error occurred during LogOut. ${error.message}.`, "authentication");
            console.error("Error logging out:", error.code, error.message);
            throw error;
        }
    }, [addError]);


    useEffect(() => {
        const unsubscribeFromAuthListener = onAuthStateChanged(auth, async (user) => {
            // setCurrentUser(user); // Set the current Firebase user
            if (user) {
                try {
                    console.log('Inside onAuthStateChange() in UserContext ');
                    const idTokenResult = await user.getIdTokenResult(true); // "true" is to force refresh the token
                    const token = idTokenResult.token; // Get the token from idTokenResult
                    setToken(token); // Update the token in the state
                    
                     // Fetch user status data including insight credits
                    let userStatusData = null; // Default empty object
                    let totalInsightCredits = null;
                    try {
                        if (user.emailVerified === true) {
                            userStatusData = await getUserStatus(user.uid,token);
                            totalInsightCredits = userStatusData.sbscrptn_insight_credits + userStatusData.extra_insight_credits;
                        }
                    } catch (fetchError) {
                        console.error("Error fetching user status:", fetchError);
                        // CRITICAL: Handle error (e.g., set error state, show a message)
                        addError("Error fetching User Account Status and Credits. Please try again in some time.", "userStatus");
                      }

                    const userAuthInfoVar = {
                        email: user.email,
                        username: user.displayName || user.email.split('@')[0],
                        emailVerified: user.emailVerified,
                        hasApprovedAccess: idTokenResult.claims.approved,
                        userTypes: {
                            isSuperAdmin: idTokenResult.claims.usertypes?.includes('superadmin'),
                            isAdmin: idTokenResult.claims.usertypes?.includes('admin'),
                            isInternal: idTokenResult.claims.usertypes?.includes('internal'),
                            isEmployee: idTokenResult.claims.usertypes?.includes('employee'),
                            isContributor: idTokenResult.claims.usertypes?.includes('contributor'),
                            isExpert: idTokenResult.claims.usertypes?.includes('expert'),
                            isCustomer: idTokenResult.claims.usertypes?.includes('customer')
                        },
                        insightCredits: totalInsightCredits 
                    };

                    setCurrentUser(user);
                    setUserAuthInfo(userAuthInfoVar);
                    setAuthCheckComplete(true);
                }
                catch (mainError) {
                    console.error("Error processing user token or data:", mainError);
                    // Handle error (e.g., set error state)
                    addError(`Error loading user information. ${mainError.message || mainError.toString()}`, "authStateChange");
                    setAuthCheckComplete(true);
                    
                }
            }
            else {
                setCurrentUser(null);
                setUserAuthInfo({
                    email: null,
                    username: null,
                    emailVerified: null,
                    hasApprovedAccess: null,
                    userTypes: {}
                });
                setAuthCheckComplete(true);
            }
           
        });
        return unsubscribeFromAuthListener;
    }, [addError])

    const updateUserInsightCredits = async () => {
        if (currentUser && userAuthInfo.emailVerified === true) {
            try {
                // Fetch the latest user status
                const userStatusData = await getUserStatus(currentUser.uid,token);
                const totalInsightCredits = userStatusData.sbscrptn_insight_credits + userStatusData.extra_insight_credits;
    
                // Update the context state with the new total
                setUserAuthInfo(prevState => ({
                    ...prevState,
                    insightCredits: totalInsightCredits
                }));
            } catch (error) {
                addError(`Error updating Insight Credits infromation. ${error.message || error.toString()}`, "userStatus");
                console.error("Error updating insight credits:", error);
                // Optionally handle the error, e.g., by showing a notification
            }
        }else{
            console.warning("Updating User's Insight credits information failed because user's email is not verified.");
        }
    };


    const contextValue = {
        authCheckComplete,
        currentUser,
        token,
        ...userAuthInfo,
        signIn,
        signUp,
        logOut,
        googleSignIn,
        updateUserInsightCredits,
        errors,
        addError,
        removeError ,
        clearErrorsByType,
        clearAllErrors
        
    };

    return (
        <userContext.Provider value={contextValue}>
            {authCheckComplete ? children : <Loader />}
        </userContext.Provider>
    );
}



