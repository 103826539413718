import React from 'react';

const Announcement = () => {
    return (
        <div className="bg-green-600 text-white text-center py-1 px-2 shadow-md text-sm">
            <p className="sm:text-sm md:text-sm lg:text-md xl:text md m-0">
                <span className="block lg:hidden">PULSE is in Beta and is not publicly accessible. Public Preview opens on May 13th 2025.</span>
                <span className="hidden lg:block xl:hidden">PULSE is in Beta and is accessible by short-listed users only. Public Preview opens on May 13th 2025</span>
                <span className="hidden xl:block">PULSE is in Beta. Access is Restricted to Partners only. Public Preview opens on May 13th 2025. So stay Tuned ! For inquiries contact support@ftredge.com.</span>
            </p>
        </div>
    );

};
export default Announcement;